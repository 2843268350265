import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async DescargarMaterialParte (Vue, idparteTrabajo, formData) {
    await Vue.$api.call('parteTrabajo.descargarMaterial', {
      idparte_trabajo: idparteTrabajo,
      idalmacen: formData.idalmacen,
    })
  },
  async selectPartesDescargarSinDevolucion (Vue, idalmacenTecnico) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('con_devolucion_pendiente', 0)
    const resp = await Vue.$api.call(
      'parteTrabajo.selectPartesDescargar', { idalmacen: idalmacenTecnico, filter: apiFilter }
    )
    return resp.data.result.dataset
  },
}
