<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <descarga-vehiculo-form
          :id="null"
          :usuario-idalmacen="usuarioIdalmacen"
          @change-values="changeFormData"
        />
      </v-form>
      <b-10-subheader
        v-if="partesParaDescargar.length > 0"
        label="Partes para descargar"
        :icon="$vuetify.icons.values.descargarVehiculo"
      />
      <v-list
        v-for="(parte, index) in partesParaDescargar"
        :key="index"
      >
        <material-vehiculo-list-item
          :item="parte"
        />
      </v-list>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import DescargaVehiculoForm from './components/DescargaVehiculoForm'
import Data from './DescargaVehiculoData'
import MaterialVehiculoListItem from '../components/MaterialVehiculoListItem'
import { get } from 'vuex-pathify'

export default {
  components: {
    DescargaVehiculoForm,
    MaterialVehiculoListItem
  },
  mixins: [formPageMixin],
  data () {
    return {
      partesParaDescargar: [],
    }
  },
  computed: {
    usuarioIdalmacen: get('usuario/idalmacen'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      if (this.routeParams.idparte_trabajo) {
        this.title = `Descargar parte Nº${this.routeParams.idparte_trabajo}`
      } else {
        this.title = 'Descargar todos los partes'
        this.partesParaDescargar = await Data.selectPartesDescargarSinDevolucion(this, this.usuarioIdalmacen)
      }
    },
    async submitForm () {
      if (this.routeParams.idparte_trabajo) {
        await Data.DescargarMaterialParte(this, this.routeParams.idparte_trabajo, this.formData)
      } else {
        for (const parte of this.partesParaDescargar) {
          await Data.DescargarMaterialParte(this, parte.idparte_trabajo, this.formData)
        }
      }
      await this.$dirty.deleted(this.$dirty.ENTITIES.remote.parteTrabajoVehiculo, this.routeParams.idparte_trabajo)
      this.$appRouter.go(-1)
    }
  }
}
</script>
